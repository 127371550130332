import { type Panel, FlickingError } from '@egjs/react-flicking';
import type { KeyboardEvent } from 'react';

import { without } from '~/util/without';

export const flickingMethod = async (method: () => Promise<void>): Promise<void> => {
	try {
		await method();
	} catch (error) {
		if (error instanceof FlickingError) {
			return;
		}

		throw error;
	}
};

export const handleKeyUpClickEvent = ({ key }: KeyboardEvent, handleClick: () => void) => {
	if ([' ', 'Enter'].includes(key)) {
		handleClick();
	}
};

export const updatePanel = ({
	isVisible,
	panel: {
		element,
	},
}: {
	isVisible: boolean,
	panel: Panel,
}) => {
	const focusableAnchorElements = element.querySelectorAll('a');

	const focusableButtonElements = element.querySelectorAll('button');

	const isFocusable = element.getAttribute('role') === 'button';

	if (isVisible) {
		element.removeAttribute('aria-hidden');

		if (isFocusable) {
			element.setAttribute('tabindex', '0');
		} else {
			element.removeAttribute('tabindex');
		}
	} else {
		element.setAttribute('aria-hidden', 'true');

		if (isFocusable) {
			element.setAttribute('tabindex', '-1');
		} else {
			element.removeAttribute('tabindex');
		}
	}

	focusableAnchorElements.forEach((focusableElement: HTMLAnchorElement) => {
		if (isVisible) {
			focusableElement.removeAttribute('tabindex');
		} else {
			focusableElement.setAttribute('tabindex', '-1');
		}
	});

	focusableButtonElements.forEach((focusableElement: HTMLButtonElement) => {
		if (isVisible) {
			focusableElement.removeAttribute('disabled');
		} else {
			focusableElement.setAttribute('disabled', 'true');
		}
	});
};

export const updatePanels = ({
	panels,
	visiblePanels,
}: {
	panels: Panel[],
	visiblePanels: Panel[],
}) => {
	const invisiblePanels = without(panels, ...visiblePanels);

	invisiblePanels.forEach((panel) => {
		updatePanel({
			isVisible: false,
			panel,
		});
	});

	visiblePanels.forEach((panel) => {
		updatePanel({
			isVisible: true,
			panel,
		});
	});
};

export const paginationSliderDotsNum = ({
	sliderPanelsCount,
	panelsPerView,
}: {
	sliderPanelsCount: number,
	panelsPerView: number,
}) => {
	return Math.ceil(sliderPanelsCount / panelsPerView);
};

export const paginationEventSliderSyncIndex = ({
	currentIndex,
	panelsPerView,
	sliderPanelsCount,
}: {
	currentIndex: number,
	panelsPerView: number,
	sliderPanelsCount: number,
}) => {
	const maxDotsIndex = Math.max(0, Math.ceil(sliderPanelsCount / panelsPerView) - 1);
	const targetIndex = Math.min(currentIndex, maxDotsIndex) * panelsPerView;
	return targetIndex;
};

export const sliderEventPaginationSyncIndex = ({
	currentIndex,
	panelsPerView,
}: {
	currentIndex: number,
	panelsPerView: number,
}) => {
	const targetIndex = Math.ceil(currentIndex / panelsPerView);
	return targetIndex;
};
