import React from 'react';

import { s7ContentPath } from '~/global/global.constants';
import styles from '#/mobile/global/header.module.scss';

class HeaderMobileLogo extends React.Component {
	render() {
		return (
			<div className={styles['header-mobile-logo-container']}>
				<a
					className="header-mobile-logo"
					href="/"
					data-track="mainLogo|home"
					data-tr-link-event-name="roomandboard"
					data-tr-link-event-comp-name="logo image"
				>
					<img src={`${s7ContentPath}/rnb-logo-black`} alt="Room & Board - Homepage." />
				</a>
				<div className="print-only here-to-help">
					We're here to help&nbsp;&nbsp;&nbsp;800.301.9720
				</div>
			</div>
		);
	}
}

export { HeaderMobileLogo };
