'use client';

import Flicking from '@egjs/react-flicking';
import dynamic from 'next/dynamic';
import React, { useRef } from 'react';

import { SiteMarketingMessages } from '~/site-marketing-messaging/Components/SiteMarketingMessages';
import { DIRECTION } from '~/site-marketing-messaging/SiteMarketingMessaging.constants';

import type { SiteMarketingMessaging as ISiteMarketingMessaging } from '~/site-marketing-messaging/Models/SiteMarketingMessaging.model';

import styles from '~/site-marketing-messaging/Components/SiteMarketingMessaging.module.scss';
import loadingStyles from '~/site-marketing-messaging/Components/SiteMarketingMessagingBackground.module.scss';
import { isEngage } from '~/global/global.constants';

const SiteMarketingMessagingBackground = dynamic(
	() => import('~/site-marketing-messaging/Components/SiteMarketingMessagingBackground').then(mod => mod.SiteMarketingMessagingBackground),
	{
		loading: () => <div className={`${loadingStyles['site-marketing-messaging-background']} ${loadingStyles['site-marketing-messaging-background-BANNER_BLACK']}`} />,
		ssr: false,
	},
);

const SiteMarketingMessagingNav = dynamic(
	() => import('~/site-marketing-messaging/Components/SiteMarketingMessagingNav').then(mod => mod.SiteMarketingMessagingNav),
	{ ssr: false },
);

const SiteMarketingMessagingPause = dynamic(
	() => import('~/site-marketing-messaging/Components/SiteMarketingMessagingPause').then(mod => mod.SiteMarketingMessagingPause),
	{ ssr: false },
);

export const SiteMarketingMessaging = ({
	siteMarketingMessagingModel,
	siteMarketingMessagingModel: {
		hasSiteMarketingMessages,
		showControls,
	},
}: { siteMarketingMessagingModel: ISiteMarketingMessaging }) => {
	const flickingRef = useRef<Flicking>(null);

	if (!hasSiteMarketingMessages || isEngage) {
		return null;
	}

	return (
		<div className="print:tw-hidden">
			<div
				className={styles['site-marketing-messaging']}
				data-qa="site-marketing-messaging"
				data-tr-link-event-comp-type="sitewide banner"
			>
				{
					showControls && (
						<p className="tw-sr-only">
							The following is a carousel of messages about events and offers at Room & Board. Use the previous, next, and pause buttons to navigate or pause the messages.
						</p>
					)
				}
				<SiteMarketingMessagingBackground siteMarketingMessagingModel={siteMarketingMessagingModel} />
				{
					showControls && (
						<SiteMarketingMessagingNav
							direction={DIRECTION.PREV}
							flickingRef={flickingRef}
							siteMarketingMessagingModel={siteMarketingMessagingModel}
						/>
					)
				}
				<SiteMarketingMessages
					flickingRef={flickingRef}
					siteMarketingMessagingModel={siteMarketingMessagingModel}
				/>
				{
					showControls && (
						<SiteMarketingMessagingNav
							direction={DIRECTION.NEXT}
							flickingRef={flickingRef}
							siteMarketingMessagingModel={siteMarketingMessagingModel}
						/>
					)
				}
				{
					showControls && (
						<SiteMarketingMessagingPause
							flickingRef={flickingRef}
							siteMarketingMessagingModel={siteMarketingMessagingModel}
						/>
					)
				}
			</div>
		</div>
	);
};
