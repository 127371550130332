/* eslint-disable jsx-a11y/no-static-element-interactions */

'use client';

import React, { forwardRef, useEffect, useRef } from 'react';

import type { ForwardedRef } from 'react';

import type { SiteMarketingMessage as ISiteMarketingMessage } from '~/site-marketing-messaging/Models/SiteMarketingMessage.model';

import styles from '~/site-marketing-messaging/Components/SiteMarketingMessage.module.scss';

export const SiteMarketingMessage = forwardRef(({
	onBlur,
	onFocus,
	siteMarketingMessage: {
		message,
		name,
		startDate,
	},
	siteMarketingMessagesCount,
	siteMarketingMessageIndex,
}: {
	onBlur?: () => void,
	onFocus?: () => void,
	siteMarketingMessage: ISiteMarketingMessage,
	siteMarketingMessagesCount: number,
	siteMarketingMessageIndex: number,
}, ref: ForwardedRef<HTMLDivElement>) => {
	const siteMarketingMessageRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const linkElements = siteMarketingMessageRef?.current?.querySelectorAll('a');

		linkElements?.forEach((linkElement) => {
			linkElement.setAttribute('data-tr-link-event-name', siteMarketingMessageRef?.current?.innerText || '');
		});
	}, [siteMarketingMessageRef.current]);

	return (
		<div
			className={styles['site-marketing-message']}
			data-qa="site-marketing-message"
			data-tr-link-event-comp-name={`${name} ${startDate}`}
			data-tr-link-event-comp-position={`${siteMarketingMessageIndex + 1}:${siteMarketingMessagesCount}`}
			onBlur={onBlur}
			onFocus={onFocus}
			ref={ref}
		>
			<div
				dangerouslySetInnerHTML={{ __html: message }}
				ref={siteMarketingMessageRef}
			/>
		</div>
	);
});
