import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import menuStyles from '#/mobile/global/menu.module.scss';

import { TopMenuMobileSubmenuPrimary } from '~/global/top-menu-mobile/Components/TopMenuMobileSubmenuPrimary';
import { TopMenuMobileSubmenuSecondary } from '~/global/top-menu-mobile/Components/TopMenuMobileSubmenuSecondary';
import { useGlobalContext } from '~/global/Contexts/Global.context';

const TopMenuMobileSubmenu = observer(() => {
	const { topMenuMobileStore } = useGlobalContext();

	const {
		menuWasActivated,
		menuWasDeactivated,
		menus = []
	} = topMenuMobileStore;

	return (
		<div
			className={
				classNames({
					[menuStyles['inside']]: true,
					[menuStyles['submenu']]: true,
					[menuStyles['submenu-slide-in']]: menuWasActivated,
					[menuStyles['submenu-slide-out']]: menuWasDeactivated,
				})
			}
			id="submenu"
			data-qa="mobile-menu-submenu"
		>
			{
				menus.map((menu, index) => {
					const {
						hiddenOnMobile,
						isSecondaryMenu,
						dataKey,
					} = menu;

					if (hiddenOnMobile && !dataKey === 'favorites') {
						return null;
					}

					return (
						<React.Fragment key={`submenuItem-${index}`}>
							{
								!isSecondaryMenu ? <TopMenuMobileSubmenuPrimary index={index} menu={menu} />
									: <TopMenuMobileSubmenuSecondary index={index} menu={menu} />
							}
						</React.Fragment>
					);
				})
			}
		</div>
	);
});

export { TopMenuMobileSubmenu };
