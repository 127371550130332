'use client';

import React, { useEffect, useState } from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { s7ContentPath, isOnServer } from '~/global/global.constants';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';

export const SearchInput = () => {
	const {
		pageStore: {
			isMediaQueryMd = false,
		} = {},
		linkEventStore = {},
	} = useGlobalContext();

	const [rfkId, setRfkId] = useState(null);

	const trackSearchResultProductClick = (event) => {
		const product = event.target.closest('li');

		if (product && product.dataset.type === 'product') {
			const productName = product.getAttribute('nm');
			const searchTerm = window.searchTracking.term;
			const link = event.target.closest('a');

			const linkEventTrackingData = {
				trLinkEventName: productName,
				trLinkEventType: LinkEventTypes.INTERNAL,
				trLinkEventCompName: `results for: ${searchTerm}`,
				trLinkEventCompType: 'preview search',
				href: link.href,
			};
			linkEventStore.trackLinkEvent(linkEventTrackingData);
		}
	};

	useEffect(() => {
		let searchContainer = null;
		if (!isOnServer) {
			searchContainer = document.getElementById('h_search');
			searchContainer.addEventListener('click', trackSearchResultProductClick);
		}
		return function () {
			if (searchContainer) {
				searchContainer.removeEventListener('click', trackSearchResultProductClick);
			}
		};
	});

	useEffect(() => {
		// hang on to the initial value - rfk forces us to lock into full or mobile widget, it can't render both
		if (isMediaQueryMd) {
			setRfkId('rfkid_6');
		}
	}, []);

	return (
		<div data-qa="h_search" id="h_search">
			<form
				method="get"
				role="search"
				action="/search/"
				suppressHydrationWarning
			>
				<span className="tw-sr-only">
					When typing in this field, a list of search results will appear and be automatically updated as you type.
				</span>
				<label htmlFor="h_searchInput" className="smBsOnly:tw-text-xs tw-text-sm tw-font-normal">
					Search
				</label>
				<input
					className="header-search-input"
					data-qa="h_searchInput"
					id="h_searchInput"
					name="query"
					type="text"
					{
						...(rfkId) && {
							'data-rfkid': rfkId,
						}
					}
				/>
				<button
					aria-label="Submit search"
					id="h_searchButton"
					data-qa="h_searchButton"
					data-tr-link-event-track={false}
				>
					<img
						alt="Search"
						src={`${s7ContentPath}/icon_search`}
					/>
				</button>
			</form>
		</div>
	);
};
